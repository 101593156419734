import React from 'react';
import {imageUrls} from './constants';
import MmcTooltip from "../tooltip";
import "./index.css";

function HighlightBox(props){

    const { title , icon , id , description, hasMultiLinedDescription , customStyle = {} , showTitleTooltip} = props;
    return (
        <div className='highlight-box' style={customStyle.container}>
        {icon && <img src={icon}  alt={icon}/>}
        { title && <div className='highlight-box-title' style={customStyle.title}> {title} </div> }
        
        {description &&  hasMultiLinedDescription 
            ? <ul className='highlight-box-description-list' style={customStyle.description}> {
                description.map( ele => <li key={ele} className='highlight-box-description'> {getEleOrIcon(ele)} </li>  )} </ul>  
            : <div className='highlight-box-description' style={customStyle.description}>  {getEleOrIcon(description,id)} </div>
        }
    </div>)

}

function getEleOrIcon(description = "", id){
    const hasBoolValue = typeof(description) === "string" && (description.indexOf("true") !== -1 || description.indexOf("false") !== -1) ;
    // <MmcTooltip text={description} customStyle={{text : {top : "60%"}}}> 
    return !hasBoolValue ? <div text={description} style={ {top : "60%"}}>  {description} {id == "welcome-benefits" ? "*" : ''} </div> : (
        <div style={{display:"flex", alignItems:"center"}}> 
            <div className='card-data-category-icon-div'> <img className='category-icon' alt={imageUrls[`${description.split(":")[1].trim()}`]} src={imageUrls[`${description.split(":")[1].trim()}`]} /> </div> 
            <span style={{marginLeft : "0.5rem"}}> {description.split(":")[0].trim()} </span>  
        </div>)
}


export default HighlightBox;
