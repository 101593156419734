import React from "react";
import { getProperty } from 'dot-prop';
import Rating from "../ratings";
import { comparePageDisplaySchema , groupDisplayBluePrint } from '../../utilities/schema';
import CompareCardFeatureBox from './compareCardFeatureBox';
import "./styles/index.css";
import "../compareCardData/styles/index.css";
import { navigate } from "gatsby";

function CompareCardData(props){
    const {data = {}} = props;

    function deleteCard(ev){
        const removeCardId =  ev.target.id;
        let compareCardIds = JSON.parse(localStorage.getItem("compareCardIds")).filter( ele => ele.id!=removeCardId );
        const cards = compareCardIds.reduce(
            (acc, item , ind ) => { 
                if(ind === compareCardIds.length-1){
                    acc+= item.id
                } else { 
                    acc+= item.id + ":" 
                }  ; return acc; } , '');
        const url_params = new URLSearchParams(window.location.search);
        if(url_params.size == 0 ){
            navigate(`/compare-cards/` + `?cards=${cards}`)
        }else{
            if(!url_params.has("cards")){
                navigate(`/compare-cards/` + window.location.search + `&cards=${cards}`)
            }else{
                url_params.delete("cards");
                let newUrl = "/compare-cards/?";
                for (let params of url_params.keys()){
                    newUrl +=  params + "=" + url_params.get(params) + "&";
                }
                navigate(newUrl + `cards=${cards}`)
            }
        }
    }

    function getData(key){
        if(Array.isArray(key)){
            return key.reduce( (acc, item ) => {
                acc[item] = getProperty(data, item, {});
                return acc;
            } , {})
        }else{
            return getProperty(data,key,{});
        }
        
    }

    return (
    <div className="compare-card-div">
        <div className="compare-card-div-header">
            <div className="remove-card" style={{cursor : "pointer"}} id={data.key}  onClick={deleteCard}> 
                <div style={{ height: "1.4rem", width: "1.4rem"}}> 
                <img  
                style={{height:"100%", width:"100%", objectFit:"contain"}} 
                id={data.key} 
                alt="close-icon"
                src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/close.svg"/>  </div>
            </div>
            <img 
                src={data.imageUrl} 
                alt={data.displayName} 
                style={{marginRight:"2rem", maxWidth:"75%"}}/> 
            <h3> {data.displayName} </h3>
            <Rating rating={data.rating} />
            <button 
                 onClick={ev =>{
                    ev.preventDefault();
                    ev.stopPropagation();
                    window.dispatchEvent(new CustomEvent("applyForCard" , {detail :  { cardId : data.key , bankId: data.bankId } }))
                }}
                className="apply-now-link-compare"> {"Apply Now"} </button> 
        </div>
        <div className="compare-card-body"> 

            {props.children}

            {comparePageDisplaySchema.map(ele => 
                <CompareCardFeatureBox 
                    key={ele.key} 
                    bluePrint={groupDisplayBluePrint[ele.key]}
                    title={ele.displayName} 
                    data={getData(ele.key)} 
                />)}
        
        </div>
    </div>
    );

}


export default CompareCardData;