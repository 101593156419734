import React, { Fragment, useState, useEffect } from "react";
import { generateOTP, verifyOTP } from "../../apis";
import { sendEventToAnalytics } from "../../utilities/tracker";
import { validatePhoneNumber } from "../../utilities/utilfns";
import "./footer-style.css";
import "./styles.css";

function FooterLogin(props){

    const [isPhoneInputDisabled, setPhoneNumberDisabled] = useState(false);
    const [userPhoneNumber, setPhoneNumber] = useState("");
    const [isInputInValid , setInputInvalid] =  useState(true);
    const [countDownValue, setCountDownValue] = useState(0);
    const [showVerifyInput, setShowVerify] = useState(false);
    const [otpInput , setOtpInput] = useState("");
    const [countDownInterval , setCountDownInterval] = useState(null);

    useEffect( ()=> {
        if(countDownValue <=0 ){
            clearInterval(countDownInterval);
        }
    }, [countDownValue] );

    function hanldeUserInput(ev){
        const phone = ev.target.value;
        setPhoneNumber(phone);
        const isPhoneValid =  validatePhoneNumber(phone);
        if(!isPhoneValid){
            setPhoneNumberDisabled(false);
            setInputInvalid(true)
        }else{
            setInputInvalid(false);
        }
    }

    
    function initiateCountDown(valueInSec){
        clearInterval(countDownInterval);
        setCountDownValue(valueInSec);
        let interval = setInterval( () => {
            setCountDownValue( prevValue => prevValue-1);
        }, 1000 );
        setCountDownInterval(interval);
    }

    function handleGenerateOTP(ev){
        ev.preventDefault();
        setShowVerify(true)
        generateOTP({
            userIdentifier  : userPhoneNumber, 
            otpMethod : "SMS"
            }).then((resp) => {
            initiateCountDown(180);
        }).catch(err => {
            window.dispatchEvent(new CustomEvent("showSnackBar", {detail : { msg : JSON.stringify(err.message), type : "alert" }}))
        });

    }

    function handleResendOTP(ev){
        ev.preventDefault();
        if(countDownValue <= 0){
            // generateOTP({email: userEmail}).then((resp) => {
            generateOTP({
                userIdentifier  : userPhoneNumber, 
                otpMethod : "SMS"
            }).then((resp) => {
                initiateCountDown(180);
            }).catch(err => {
                window.dispatchEvent(new CustomEvent("showSnackBar", {detail : { msg : JSON.stringify(err.message), type : "alert" }}))
            });    
        }
    }

    function sendGaEvent(eventType){
        sendEventToAnalytics({
            eventName :  eventType,
            eventLabel  :  eventType ,
            eventAction : eventType,
            eventConfig : {}
        });
    }

    function handleVerifyOTP(ev){
        ev.preventDefault();
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
        verifyOTP({ 
            ...userInfo,
            userIdentifier  : userPhoneNumber, 
            otpMethod : "SMS", 
            otp : otpInput, 
            }).then(
            resp => {
                clearInterval(countDownInterval);
                sessionStorage.setItem("userInfo", JSON.stringify({
                    ...userInfo,
                    name : resp.data.name, 
                    userIdentifier : resp.data.userIdentifier, 
                    token : null, 
                    source: "otp"}));
                    props.close();
                    sendGaEvent("Phone-signup-footer");
                    window.dispatchEvent(new Event("session-storage")); 
            }).catch(err => {
                window.dispatchEvent(new CustomEvent("showSnackBar", {detail : { msg : JSON.stringify(err.message), type : "alert" }}))
            })

    }

    return(
        <div className="footer-login">
            <div className="footer-login-header">
                <div className="footer-login-title"> {"Your Savings Await!"} <br/> {"Login for Exclusive Offers.."}
                </div>
                <div>
                    <div className="login-form-no-spam">  
                        <img alt="No Spam" src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/shield.svg"/>
                        <span style={{fontSize : "0.75rem"}}> {"No Spam"} </span>
                    </div>
                </div>
            </div>
            <div className="footer-login-body">
                {!showVerifyInput 
                    ? <Fragment> 
                    <div style={{minWidth : "75%"}}> 
                        <div className="footer-login-phone"> {"Phone number"} </div>
                        <div className="user-phone-input-cont">
                            <img alt="india" src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/india.svg"/>
                            <span className="phone-prefix"> {"+91"} </span>
                            <input 
                                value={userPhoneNumber} 
                                className="user-input"
                                disabled={isPhoneInputDisabled ? "disabled" : "" } 
                                placeholder="Enter phone number"
                                onChange={hanldeUserInput}
                            />
                        </div>    
                        {isInputInValid && userPhoneNumber.length >0  && (<div className="alert-text"> {"Please enter a valid phone number"} </div>) }
                    </div>
                    <button  
                        disabled={isInputInValid}
                        onClick={handleGenerateOTP}
                        className="login-footer-otp-btn"> {"Send OTP"} 
                    </button>
                    </Fragment> 
                    : <Fragment>
                        <input 
                            placeholder="Enter your OTP" 
                            value={otpInput}
                            className="login-footer-otp-input"
                            onChange={ ev => { 
                                ev.preventDefault();
                                setOtpInput(ev.target.value.trim());
                            } }
                            />
                         <div 
                            className={`resend-code ${countDownValue>0 ? "code-disable" : "" }`} 
                            onClick={handleResendOTP}>
                            {"Resend Code " + countDownValue + "s"} 
                        </div>
                        <button  
                            onClick={handleVerifyOTP}
                            className="login-footer-otp-btn"> {"Verify OTP"} 
                        </button>
                    </Fragment>}
                
                </div>
        </div>
    )

}

export default FooterLogin;