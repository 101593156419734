import React , {Fragment, useEffect, useState} from "react";
import Rating from "../ratings";
import BestForIcons from '../bestForIcons';
import { navigate } from "gatsby";
import { rewardType , redemptionTypes , availableComplimentaryFeatures} from './constants';

import "./styles/styles.css";
import "./styles/suggested-cards.css";
import "../cardMetaData/styles.css"

function SuggestedCardDesktop(props){
    const { cardData ={} , matchData = {} , isUserLoggedIn} = props;
    const userSpending = JSON.parse(sessionStorage.getItem("userSpending") || "{}" );
    const availableComplimentaryFeatues = {};
    function applyNow(ev){
        ev.preventDefault();
        ev.stopPropagation();
        window.dispatchEvent(new CustomEvent("applyForCard", { detail : {cardId : cardData.key, bankId : cardData.bankId } }))
    }
    function pageNavigate(ev){
        ev.preventDefault();
        navigate("/card-details/" + cardData.key + window.location.search );
    }

    return(
        <div key={matchData.cardId} className="suggested-card-container-desktop">
            <div className="suggested-card-header-desktop"> 
                <div>
                    <div className="suggested-card-title" style={{fontSize : "1.25rem"}}> {cardData.displayName} </div>
                    <Rating rating={cardData.rating}/>
                </div>
                <div className="suggested-card-desktop-flex-row">
                    <div className="suggested-card-desktop-flex-col">
                        <div className="approval-chance-title" style={{fontSize : "1rem"}}> {"Approval Chance"} </div>
                        {isUserLoggedIn ? (
                            <div className="approval-chance-badge">
                                <div className="approval-chance-badge-high" style={{fontSize : "1rem"}}> {"High"} </div>
                                <img alt="approval-high" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/approval-high.svg"}/>  
                            </div> ) : ( 
                            <div className="locked-approval-chance">
                                <img alt="lock" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/lock.svg"}/>  
                            </div>)
                        }
                    </div>    
                    <div className="suggested-card-apply-btn" onClick={applyNow}> {"Apply Now"} </div>         
                </div>  
            </div>
            <div className="suggested-card-body-desktop"> 
                <div className="suggested-card-details">
                    <div className="suggested-card-body-sec1-desktop">
                        <img alt="suggested-card-body-card-img-desktop" className="suggested-card-body-card-img-desktop" src={cardData.imageUrl}/>
                        {/* <div 
                            className="my-cards-card-addToCompare"
                            style={{marginTop:"1rem", alignItems:"center", cursor: "pointer"}} 
                            id={cardData.key} 
                            key={cardData.key}
                            onClick={ (ev) => { ev.preventDefault(); addToCompare(cardData) }}>
                            <input 
                                type="checkbox" 
                                // checked={isChecked} 
                                value={cardData.key} id={cardData.key} style={{cursor : "pointer"}} /> 
                            <span id={cardData.key} style={{color: "#3D8BFF"}}> {'Add to Compare'} </span>
                        </div> */}
                        <div style={{display:"flex" , alignItems:"center", margin : "1rem 0", fontSize : "1rem", color : "#414141"}}>
                            {"Best for these"}
                            <div className="best-for-icons-container" style={{margin : "0.5rem 0"}}>  {<BestForIcons bestFor={cardData.bestFor} />} </div>
                        </div>
                    </div>
                    <div className="suggested-card-body-sec2">
                        <div>
                            { matchData?.isAnnualFeesWaived && cardData.feesAndCharges.annualFees != "0" && (<div className="suggested-card-annual-waived-off">
                                <img 
                                    style={{marginRight : "0.5rem"}} 
                                    alt="party"
                                    src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/party.svg"}/>
                                <div> {"Congratulations! Annual Fee will be waived based on your spending"} </div>
                            </div>)}
                            { matchData?.dataToSend?.vouchers && (
                                <div className="suggested-card-total-rewards"> 
                                    <img 
                                        alt="suggested-card-reward-img"
                                        style={{cursor : "pointer"}} 
                                        className="suggested-card-reward-img" 
                                        src={rewardType["vouchers"]["src"]}/>
                                    <span className="suggested-card-reward-value"> 
                                        <b style={{fontSize : "0.75rem"}}>{matchData?.dataToSend?.["vouchers"]?.["text"]} </b>
                                    </span>
                                </div>
                            )}
                            <div className="suggested-card-total-rewards" 
                                style={{ justifyContent : "space-between" }}> 
                                <div className="reward-text">
                                    <img 
                                        alt="suggested-card-reward-img"
                                        className="suggested-card-reward-img" src={rewardType[matchData?.dataToSend?.type]?.["src"]}/>
                                    <div className="suggested-card-reward-value"> 
                                        {matchData?.dataToSend?.text.indexOf("worth") > -1 
                                        ? ( <Fragment>
                                                <b  style={{fontSize : "0.75rem"}}>{matchData?.dataToSend?.text.split("worth")[0]} </b>
                                                <span  style={{fontSize : "0.75rem"}}> { "worth " + matchData?.dataToSend?.text.split("worth")[1]} </span>
                                            </Fragment>
                                        ) 
                                        : (
                                            <Fragment>
                                                <b  style={{fontSize : "0.75rem"}}>{matchData?.dataToSend?.text.split("Total")[0]} </b>
                                                <span  style={{fontSize : "0.75rem"}}> {matchData?.dataToSend?.text.split("Total")[1]} </span>
                                            </Fragment>
                                        ) }
                                    </div>
                                </div>
                            </div>
                            { matchData.dataToSend.redemptionTypes &&  <div>
                                    <div className="show-redemption-options-container">
                                        {matchData.dataToSend.redemptionTypes.map(ele => 
                                            (
                                                <div className="suggested-card-redemption-opt">  
                                                    <img 
                                                        alt="suggested-card-reward-img"
                                                        className="suggested-card-reward-img" 
                                                        src={redemptionTypes[ele.type]}/>
                                                    <div className="suggested-card-reward-text"> 
                                                        <b style={{fontSize : "0.75rem"}}> {ele.text.split("worth")[0]} </b>
                                                        { "worth "  + ele.text.split("worth")[1] + " *"}
                                                    </div>
                                                </div>
                                            )    
                                        )}
                                    </div>
                                </div> 
                            }  
                            {
                                matchData.dataToSend.type == "cashback" && (
                                    <div className="suggested-card-redemption-opt">  
                                        <img 
                                            alt="suggested-card-reward-img"
                                            className="suggested-card-reward-img" 
                                            src={redemptionTypes["voucher"]}/>
                                        <div className="suggested-card-reward-text"> 
                                            <b style={{fontSize : "0.75rem"}}> {cardData.savings.percentage} </b>
                                            {cardData.savings.details}
                                        </div>
                                    </div>
                                )
                            }
                        </div>                
                    </div>
                    <div style={{flexBasis : "15%" , margin : "0 1rem"}}>
                        <div className="suggested-card-fees">
                            <span className="suggested-card-fees-title-desktop">{"Joining Fee"}</span><br/>
                            <span className="suggested-card-fees-value-desktop">  {
                                (cardData.feesAndCharges.joiningFees == 0 || cardData.feesAndCharges.joiningFees == "Not Available") ? 
                                    <span className="suggested-card-fees-value-free">{"Free"}</span> :
                                cardData.feesAndCharges.joiningFees } </span> <br/>
                            <span className="suggested-card-fees-title-desktop">{"Annual Fee"}</span><br/>
                            <span className="suggested-card-fees-value-desktop"> {cardData.feesAndCharges.annualFees} </span>
                        </div>
                        <div className="available-features-badges-container"> 
                            <span 
                                style={{ color : "#000" ,margin : "1rem 0 0.5rem 0", fontSize :"1rem", fontSize : "0.88rem"}}>{"Avaliable features:"}</span> 
                            {Object.keys(availableComplimentaryFeatures).map( ele => 
                                (<div className="feature-badge" style={{ 
                                    padding : "0.23rem",
                                    background : matchData?.features?.[ele] && "#E6F3E5",
                                    color : matchData?.features?.[ele] &&  "#108510"  
                                }}> {availableComplimentaryFeatures[ele]} </div>)
                            )}
                            
                        </div>
                    </div>
                </div>          
            </div>
            <div className="suggested-card-footer-desktop" onClick={ pageNavigate }> 
                <div style={{   
                    display : "flex", alignItems : "center" , justifyContent : "space-between",
                    margin: "0rem", padding: "0.5rem", background: "#eef7ff", 
                    fontSize : "0.875rem"}}>
                    <span> {"Card Details"} </span>
                    <button style={{background : "#eef7ff", border : "none"}} className="details-btn"  > {"->"} </button>
                </div>
            </div>
        </div>
    )
}

export default SuggestedCardDesktop;