// export const cardDataSchema = [
//     "displayName",
//     "key", 
//     "imageUrl", 
//     "isActive", 
//     "rating", 
//     "cardType", 
//     "cardCategory",
//     {"savings" : ["percentage", "details"]},
//     {"tipsByCredzy" : ["idealYearlySpends", "idealMonthlySpends", "idealSpendsCategories"]},
//     {"feesAndCharges" : ["joiningFees","annualFees","feeReversalCriterai","forexMarkupCharges"]},
//     { 
//         "joiningAnnualAndMilestoneBenefits" : ["joiningBenefit" , "annualBeneift", 
//         {"milestoneBenefit" : ["monthlyMilestoneBenefit", "quarterlyMilestoneBenefit", "annualMilestoneBenefit" ]} 
//     ]},
//     { "features": [
//         "membershipWithCard" , {
//         "loungeAccess" : [
//             "loungeAccessMembership", 
//             {"forCardHolders" : ["railway", "domestic", "international" ]},
//             {"forGuest" : ["railway", "domestic", "international" ]},
//         ]},
//        { "golf": ["game", "lessons" ]},
//        "spa",
//        {"insurance" : ["airAccidentalInsurance","personalAccidentalInsurance", "lostCardLiabilitycover" ]},
//        "airportMeetGreetService",
//        "airportTransferService"
//     ]
//     },
//     {"rewards" : ["accerlatedRewards", "capping", "others" , "spendCategoryThatWillNotEarnReward",
//                 { "online": ["rewardsOnSpends", "estimatePercentageSavings" ]},
//                 { "offline": ["rewardsOnSpends", "estimatePercentageSavings" ]},] },
//     {"fuelSpendBenefits" : ["waiver","minTransactionAmount","maxTransactionAmount","maxSurchargeWaiver"]},
//     {"rewardsRedemption" : ["bestRedemptionTip","rewardRedemptionOption","rewardRedemptionFees","rewardRateOfEachOption", "rewardTransferPartner"]},
//     {"eligibility" : ["residentialStauts", "minimumAge", "maximumAge", "minimumSalary", "minimumITR" ]},
// ]

export const cardDataDisplaySchema = [   
    {
        key : "rewards",
        displayName : "Rewards",
    },
    {
        key : "rewardsRedemption",
        displayName : "Rewards Redemption",
    }, 
    {
        key : "features",
        displayName : "Complementary Benefits",
    },  
    // {
    //     key : "tipsByCredzy",
    //     displayName : "Tips by Credzy"
    // },
    // {
    //     key : "fuelSpendBenefits",
    //     displayName : "Fuel Benefits",
    // },
    [ 
        // {
        //     key : "eligibility",
        //     displayName : "Eligibility"
        // },
        // {
        //     key : "tipsByCredzy",
        //     displayName : "Tips by Credzy"
        // },
        // {
        //     key : "fuelSpendBenefits",
        //     displayName : "Fuel Benefits",
        // },
    ],
    
    {
        key : "feesAndCharges",
        displayName : "Fees",
    },
    {
        key : "eligibility",
        displayName : "Eligibility"
    },

];

export const comparePageDisplaySchema = [   
    {
        key : "rewards",
        displayName : "Rewards",
    },
    {
        key : "rewardsRedemption",
        displayName : "Rewards Redemption",
    }, 
    {
        key : "features",
        displayName : "Complementary Benefits",
    },  
    // {
    //     key : "tipsByCredzy",
    //     displayName : "Tips by Credzy"
    // },
    // {
    //     key : "fuelSpendBenefits",
    //     displayName : "Fuel Benefits",
    // },  
    {
        key : "feesAndCharges",
        displayName : "Fees",
    },
    {
        key : "eligibility",
        displayName : "Eligibility"
    },

];




const featuresBluePrint = {
    key : "features",
    displayKeys : [
        {
            isGroup : true,
            items : [ {
                key : "loungeAccess.forCardHolders",
                displayName : "Lounge access",
                displayKeys : [
                    {
                        key : "loungeAccess.forCardHolders.domestic",
                        displayName : "Domestic Lounge",
                        type : "string"
                    },
                    {
                        key : "loungeAccess.forCardHolders.international",
                        displayName : "International Lounge",
                        type : "string"
                    },
                    {
                        key : "loungeAccess.forCardHolders.railway",
                        displayName : "Railway Lounge",
                        type : "string"
                    },
                ]
            }, 
            // {
            //     key : "loungeAccess.forGuest",
            //     displayName : "Lounge access for guest",
            //     displayKeys : [
            //         {
            //             key : "loungeAccess.forGuest.railway",
            //             displayName : "Railway Lounge",
            //             type : "string"
            //         },
            //         {
            //             key : "loungeAccess.forGuest.domestic",
            //             displayName : "Domestic Lounge",
            //             type : "string"
            //         },
            //         {
            //             key : "loungeAccess.forGuest.international",
            //             displayName : "International Lounge",
            //             type : "string"
            //         },
            //     ]
            // },
           
            {
                key : "insurance",
                level : 1,
                displayName : "Insurance Benefits",
                displayKeys : [
                    {
                        key : "insurance.lostCardLiabilitycover",
                        displayName : "Lost card liability",
                        type : "string"
                    },
                    {
                        key : "insurance.personalAccidentalInsurance",
                        displayName : "Personal accidental insurance",
                        type : "string"
                    },
                    {
                        key : "insurance.airAccidentalInsurance",
                        displayName : "Air accidental insurance",
                        type : "string"
                    },
                ]
            },  
            {
                key : "other benefits",
                level : 1,
                displayName : "Other Benefits",
                displayKeys : [
                {
                    key : "loungeAccess.loungeAccessMembership",
                    displayName : "Lounge Membership",
                    type : "string",
                    level : 1,
                }, 
                {
                    key : "membershipWithCard",
                    displayName : "Memberships Available With Cards",
                    type : "string",
                    level : 1,
                },    
                {
                    key : "spa",
                    displayName : "Spa Benefits",
                    type : "string",
                    level : 1,
        
                }]  
            },
            {
                key : "golf",
                level : 1,
                displayName : "Golf Benefits",
                displayKeys : [
                    {
                        key : "golf.lessons",
                        displayName : "Lessons",
                        type : "string"
                    },
                    {
                        key : "golf.game",
                        displayName : "Games",
                        type : "string"
                    },
                ]
            },
        
        ]

        },
        
       
        // {
        //     key : ["airportMeetGreetService" , "airportTransferService"],
        //     displayName : "Airport Transfer and Greetings",
        //     type : "string",
        //     level : 1,
        // },  
    ]
};


const rewardsBluePrint = {
    key : "rewards",
    displayKeys : [
        {
            isGroup : true, 
            items : [
                {
                    key: "rewardsList",
                    displayName : "Core Rewards",
                    type: "array",
                    level : 1, 
                },
            ]
        },
        // {
        //     key: ["accerlatedRewards" , "others"],
        //     displayName : "Core Reward",
        //     type: "array",
        //     level : 1,
        // },
        {            
            isGroup : true,
            items : [
                {
                    key:  "milestone",
                    displayName : "Milestone Rewards",
                    level : 1,
                    displayKeys : [

                        {
                            key: "milestoneBenefit.monthlyMilestoneBenefit",
                            displayName : "Monthly",
                            type: "string"
                        },
                        {
                            key: "milestoneBenefit.quarterlyMilestoneBenefit",
                            displayName : "Quaterly",
                            type: "string"
                        },
                        {
                            key: "milestoneBenefit.annualMilestoneBenefit",
                            displayName : "Annual",
                            type: "string"
                        },
                        // {
                        //     key: "online.rewardsOnSpends",
                        //     displayName : "Monthly",
                        //     type: "string"
                        // },
                        // {
                        //     key: "online.estimatePercentageSavings",
                        //     displayName : "Annual",
                        //     type: "string"
                        // },
                    ]
                },
                // {
                //     key:  "offline",
                //     displayName : "Offline spends",
                //     level : 1,
                //     displayKeys : [
                //         {
                //             key: "offline.rewardsOnSpends",
                //             displayName : "Rewards",
                //             type: "string"
                //         },
                //         {
                //             key: "offline.estimatePercentageSavings",
                //             displayName : "Estiamted percentage savings",
                //             type: "string"
                //         },
                //     ]
                // },
            ]
        },
        // {
        //     key: "capping",
        //     displayName : "Capping",
        //     type: "string",
        //     level : 1,
        // },
        {
            isGroup : true,
            items : [
                {
                    key: "spendCategoryThatWillNotEarnReward",
                    displayName : "No rewards on spends",
                    type: "string",
                    level : 1,
                }, 
            ]
        }
       
    ]
}

const fuelSpendsBluePrint = {
    key : "fuelSpendBenefits",
    displayKeys : [
        {
            key: "waiver",
            displayName : "Waiver",
            type: "string",
            level : 1,
        },
        {
            key: "minTransactionAmount",
            displayName : "Minimum transaction amount",
            type: "string",
            level : 1,
        },
        {
            key: "maxTransactionAmount",
            displayName : "Maximum transaction amount",
            type: "string",
            level : 1,
        },
        {
            key: "maxSurchargeWaiver",
            displayName : "Capping",
            type: "string",
            level : 1,
        },
    ]
}

const rewardsRedemptionBluePrint = {
    key : "rewardsRedemption",
    displayKeys : [
        {
            isGroup : true,
            items : [
                {
                    key: "redemptionList",
                    displayName : "How to Redeem",
                    type: "string",
                    level : 1,
                },
            ]
        },
        {
            isGroup : true,
            items : [
                {
                    key: "rewardRedemptionOption",
                    displayName : "Redemption options",
                    type: "string",
                    level : 1,
                },
                {
                    key: "rewardRedemptionFees",
                    displayName : "Redemption fee",
                    type: "string",
                    level : 1,
                },
                // {
                //     key: "bestRedemptionTip",
                //     displayName : "Best redemption tip",
                //     type: "string",
                //     level : 1,
                // },
            ]
        },{
            isGroup : true, 
            items : [
                {
                    key: "rewardTransferPartner",
                    displayName : "Transfer partners",
                    type: "array",
                    level : 1,
                },
            ]
        }

    ]
}

const eligibilityBluePrint = {
    key : "eligibility",
    displayKeys : [
        {
            isGroup : true,
            items : [
                {
                    key: "minimumAge",
                    displayName : "Minimum age",
                    type: "string",
                    level : 1,
                },
                // {
                //     key: "maximumAge",
                //     displayName : "Maximum age",
                //     type: "string",
                //     level : 1,
                // },
                {
                    key: "minimumITR",
                    displayName : "Minimum ITR",
                    type: "string",
                    level : 1,
                },
                {
                    key: "minimumSalary",
                    displayName : "Minimum Salary",
                    type: "string",
                    level : 1,
                },
            ]
        }
        // {
        //     key: "residentialStauts",
        //     displayName : "Residential Status",
        //     type: "string",
        //     level : 1,
        // },

    ]
}

const feesAndChargesBluePrint = {
    key : "feesAndCharges",
    displayKeys : [ {
        isGroup : true ,
        items : [
            {
                key: "joiningFees",
                displayName : "Joining fees",
                type: "string",
                level : 1,
            },
            {
                key: "annualFees",
                displayName : "Annual fees",
                type: "string",
                level : 1,
            },
            // {
            //     key: "forexMarkupCharges",
            //     displayName : "Forex markup charges",
            //     type: "string",
            //     level : 1,
            // },
            // {
            //     key: "feeReversalCriterai",
            //     displayName : "Fee reversal criteria",
            //     type: "string",
            //     level : 1,
            // },
        ]    
    }]
}


const otherChargesBluePrint = {
    key : "feesAndCharges",
    displayKeys : [ {
        isGroup : true ,
        items : [
            {
                key: "forexMarkupCharges",
                displayName : "Forex markup charges",
                type: "string",
                level : 1,
            },
            {
                key: "feeReversalCriterai",
                displayName : "Fee reversal criteria",
                type: "string",
                level : 1,
            },
        ]    
    }]
}

const tipsByCredzyBluePrint = {
    key : "tipsByCredzy",
    displayKeys : [
        {
            key: "idealYearlySpends",
            displayName : "Ideal yearly spends",
            type: "string",
            level : 1,
        },
        {
            key: "idealMonthlySpends",
            displayName : "Ideal monthly spends",
            type: "string",
            level : 1,
        },
        {
            key: "idealSpendsCategories",
            displayName : "Ideal spend categories",
            type: "string",
            level : 1,
        },
    ]
}

const joiningAnnualAndMilestoneBenefitsBluePrint = {
    key : "joiningAnnualAndMilestoneBenefits",
    displayKeys : [
        {
            isGroup : true, 
            items : [
                {
                    key: "joiningBenefit.longDescription",
                    displayName : "Welcome Benefits",
                    type: "string",
                    level : 1,
                },
                {
                    key: "quaterlyMilestone",
                    displayName : "Quaterly Benefits",
                    type: "string",
                    level : 1,
                },
                {
                    key: "annualBeneift",
                    displayName : "Annual Benefits",
                    type: "string",
                    level : 1,
                },
            ]
        } 
    ]
}

export const groupDisplayBluePrint = { 
    rewards : rewardsBluePrint,
    otherCharges : otherChargesBluePrint,
    rewardsRedemption: rewardsRedemptionBluePrint,
    features : featuresBluePrint,
    fuelSpendBenefits : fuelSpendsBluePrint,
    feesAndCharges : feesAndChargesBluePrint,
    joiningAnnualAndMilestoneBenefits: joiningAnnualAndMilestoneBenefitsBluePrint,
    tipsByCredzy : tipsByCredzyBluePrint,
    eligibility : eligibilityBluePrint,
};
