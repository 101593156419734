import React from "react";
import { Link } from 'gatsby';
import "./styles.css";
import MmcTooltip  from "../tooltip";

function BestForIcons(props){
    const {bestFor = []} = props;
    return bestFor.map( ele => {
        const categoryItemTag = Object.keys(ele).reduce( (acc, item) => {  acc +=  item !="_id" ? ele[item] : "";  return acc;} , "" );
        const tooltipText = categoryItemTag.split("-").reduce( (acc, item ) => { acc+= item + " "  ;return acc; } , "").trim(); 
        let imageName = "";
        if(categoryItemTag.includes("best")){
            imageName = "best";
        }else if(categoryItemTag.includes("lounge")){
            imageName = "lounge";
        }else if(categoryItemTag.includes("free")){
            imageName = "free";
        }
        else if(categoryItemTag.includes("utilities")){
            imageName = "utility";
        }
        else if(categoryItemTag.includes("online-shopping")){
            imageName = "shopping-bag";
        }
        else if(categoryItemTag.includes("transaction")){
            imageName = "international-transactions";
        }else{
            imageName = categoryItemTag;
        }
        return ( 
        <div className="category-icon-container"> 
            <Link to={"/card-category/" + categoryItemTag}> 
                <div className="card-data-category-icon-div"> 
                    <MmcTooltip text={tooltipText} customStyle={{ text : { width : "max-content" } }}>
                        <img  
                            className="category-icon" 
                            alt={imageName}
                            src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/" + imageName + ".svg"} />   
                    </MmcTooltip>
                </div> 
            </Link> 
        </div>
        );
    }  );
}

export default BestForIcons;