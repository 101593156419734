import React, { useState } from "react";

function SpendsSidebar(props){
    const { userSpends = {}, showUserSpends   } = props;
    // {"spends":{"e-commerce":{"value":"9000","spendType":"monthly"},"food-ordering":{"value":"8000","spendType":"monthly"},"flights-and-hotels":{"value":"9000","spendType":"monthly"},"utilities":{"value":"9000","spendType":"monthly"},"fuel":{"value":"9500","spendType":"monthly"},"insurance":{"value":"7500","spendType":"monthly"},"offline":{"value":"4500","spendType":"monthly"},"other-spends":{"value":"9000","spendType":"monthly"}},"eligibilityData":{"age":18,"occupation":"salaried","credit-score":"760-800"},"featuresSelected":{"international-lounge":true,"domestic-lounge":true,"add-on-card":true}}
    const spends = Object.keys(userSpends.spends).map( ele => ({ 
        displayName : getModifiedName(ele),
        value : userSpends.spends[ele].spendType == "monthly" ?  userSpends.spends[ele].value*12 :  userSpends.spends[ele].value,
     }) );
    const eligibilityData = Object.keys(userSpends.eligibilityData).map( ele => (
        {
            displayName : getModifiedName(ele),
            value : userSpends.eligibilityData[ele]
        }
    ) );
    const features = Object.keys(userSpends.featuresSelected).map( ele => getModifiedName(ele) );

    function toggleShowUserSpends(ev){
        ev.preventDefault();
        props.setShowUserSpends(!showUserSpends);
    }


    return(
        <div className={`user-input-container`} style={{ flexBasis : showUserSpends ? "20%" : "0%" }} > 
            <div className={`spends-container-user-data-container ${showUserSpends ? "user-data-container-open" : "user-data-container-close"}`}>
                <div>
                    <span className="input-grp-header"> {"Annual spends"} </span>
                    <div style={{padding : "1rem"}}>
                        {spends.map( ele => (<div className="user-inp-spend-cat"> 
                            <div style={{marginBottom : "0.5rem", color : "#494949"}}> {ele.displayName} </div>
                            <div className="d-flex-ai-c"> 
                                <img alt={ele.displayName} className="icon-image" src="https://cdn-icons-png.flaticon.com/512/3998/3998647.png"/>
                                <span> {ele.value} </span>
                            </div>
                        </div>) )}
                    </div>
                </div>
                
                <div>   
                    <span className="input-grp-header"> {"Features selected"} </span>
                    <ul> {features.map( ele => <li> {ele} </li>  )} </ul>
                </div>
                    
                <div>  
                    <span className="input-grp-header"> {"Eligibility data"} </span>
                    <div style={{padding: "1rem"}}>
                        {eligibilityData.map( ele => (
                            <div  className="d-flex-ai-c my-cards-elig"  >
                                <div style={{ fontWeight : "550", marginRight :"1rem" }}> {ele.displayName} </div>
                                <div> {ele.value} </div>
                        </div>) )}
                    </div>
                </div>
            </div>
        <div className={`toggle-user-spend-sidebar`} style={{ left : showUserSpends ? "95%" : "0" }}  onClick={toggleShowUserSpends}>
                {showUserSpends 
                    ?  <img alt="left-arrow" style={{height : "1rem", width : "1rem", padding : "0.5rem"}}  src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/left-arrow.svg"/>  
                    : <img  alt="right-arrow" style={{height : "1rem", width : "1rem", padding : "0.5rem"}}  src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/right-arrow.svg"/> 
                }
            </div>
        </div>
        
    )
}


function getModifiedName(value){
   return  value.split("-").reduce( (acc, item) => { acc += item.charAt(0).toUpperCase() + item.slice(1) + " "  ; return acc; }, "");
}

export default SpendsSidebar;