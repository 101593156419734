import React,{ useEffect, useState} from "react";
import { findMyMatchApi , fetchCardById } from "../../apis";
import { Loading } from 'react-loading-dot';
import lottie from 'lottie-web';

import Layout from "../../components/layout";
import MyCards from '../../components/myCards';
import "./find-my-match.css";
import { navigate } from "gatsby";


function CardForMe(){

    const [userSpendsData, setUserSpends] =  useState({});
    const [matchData , setMatchData ] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const bannerImgRef = React.useRef(null);

    useEffect( () => {
        const userSpending = JSON.parse(sessionStorage.getItem("userSpending")) || {};
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || {};

        if(Object.keys(userSpending) == 0 || Object.keys(userSpending?.userSpends) == 0   ){
            navigate("/find-my-match" + window.location.search);
        }

        const eligibilityData = { 
            income :  userSpending.income , 
            "credit-score" : userSpending.creditScore,
            occupation : userSpending.employmentType,
            age : ""
        }

        let featuresSelected = userSpending.complimentaryBenefits;
        featuresSelected = !featuresSelected ? {} : featuresSelected;
        setUserSpends({ 
            spends : userSpending.userSpends, 
            eligibilityData, 
            featuresSelected
        });
        const payload = { 
            userInput : userSpending.userSpends,
            eligibilityData, 
            featuresSelected,
            userInfo
        };
        const matchData = JSON.parse(sessionStorage.getItem("cardsForMe") || "[]")
        if(matchData.length > 0){
            setMatchData([...matchData]);
            setShowLoader(false);
        }else{
            let startTime = Date.now();
            findMyMatchApi(payload).then(res => {
                const matchedCards = res.data;
                const getAllCardDetailsPromise  = matchedCards.map( ele =>  fetchCardById(ele.cardId) );
                Promise.all(getAllCardDetailsPromise).then(response  => {
                    const modififedResp =  response.map( (ele,ind) => ({ cardData : ele.data , matchData : matchedCards[ind]}) );
                    sessionStorage.setItem("cardsForMe", JSON.stringify([...modififedResp]));
                    let time = (Date.now() - startTime) < 5000 ? 5000 - (Date.now() - startTime) : 0;
                    setTimeout( () => {
                        setMatchData([...modififedResp]);
                        setShowLoader(false);
                    } , time );
                })
            }).catch(err => {
                window.dispatchEvent(new CustomEvent("showSnackBar", {detail : { msg : JSON.stringify(err.message), type : "alert" }}))
            });
        }
    }, [] );

    useEffect( () => {
        const anim = lottie.loadAnimation({
          container: bannerImgRef.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: 'https://credzyimages.s3.ap-south-1.amazonaws.com/animations/loading-ffm-lottie.json',
        });
        
        return () => {
            anim.destroy();   
        }

      } , []);

    return (
        <Layout>
            {showLoader ?  <div 
                className="loader-parent">  
                <div className="lottie-loader" style={{display : "flex", alignItems : "center" }}>
                    <div className="loader-text">
                        <div> {"Hang on!!"} </div>
                        <div> {"We're making magic happen!"} </div>
                    </div>
                    <div  className="hangon-lottie" id="hangon-lottie" ref={bannerImgRef}>  </div>
                    {/* <Loading style={{position:"absolute"}} background={"#03549b"} />  */}
                </div>
                </div>  : (<div>
                <MyCards matchData={matchData} userSpends={userSpendsData}/>
            </div>)}

        </Layout>
    )
}

export const Head = () => (
    <React.Fragment>
      <title>Your Cards</title>
    </React.Fragment>);

export default CardForMe;