import React , {Fragment, useState} from "react";
import Rating from "../ratings";
import BestForIcons from '../bestForIcons';
import { navigate } from "gatsby";

import "./styles/styles.css";
import "./styles/suggested-cards.css";

import { rewardType , redemptionTypes , availableComplimentaryFeatures} from './constants';

function SuggestedCard(props){
    const { cardData ={} , matchData = {}, isUserLoggedIn } = props;
    const [isShowRedemptionTypes, showRedemptionTypes] = useState(true);
    function applyNow(ev){
        ev.preventDefault();
        ev.stopPropagation();
        window.dispatchEvent(new CustomEvent("applyForCard", { detail : {cardId : cardData.key, bankId : cardData.bankId } }))
    }
    function pageNavigate(ev){
        ev.preventDefault();
        navigate("/card-details/" + cardData.key + window.location.search );
    }

    return(
        <div key={matchData.cardId} className="suggested-card-container">
            <div className="suggested-card-header"> 
                <div className="suggested-card-title"> {cardData.displayName} </div>
                <Rating rating={cardData.rating}/>
            </div>
        <div className="suggested-card-body"> 
            <div className="suggested-card-details">
                <div className="suggested-card-body-sec1">
                    <img alt={cardData.imageUrl} className="suggested-card-body-card-img" src={cardData.imageUrl}/>
                    <div className="approval-chance-title"> {"Approval Chance"} </div>
                    <div className="approval-chance-badge">
                        {isUserLoggedIn ? <Fragment> 
                            <div className="approval-chance-badge-high"> {"High"} </div>
                            <img alt="approval-high" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/approval-high.svg"}/>  
                        </Fragment> :  
                            <div className="locked-approval-chance">
                                <img alt="lock" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/lock.svg"}/>  
                            </div>
                        }
                    </div>
                </div>
                <div className="suggested-card-body-sec2">
                    <div className="suggested-card-fees">
                        <span className="suggested-card-fees-title">{"Joining Fee"}</span><br/>
                        <span className="suggested-card-fees-value">  {
                            (cardData.feesAndCharges.joiningFees == 0 || cardData.feesAndCharges.joiningFees == "Not Available") ? 
                                <span className="suggested-card-fees-value-free">{"Free"}</span> :
                            cardData.feesAndCharges.joiningFees } </span>
                    </div>
                    <div className="suggested-card-fees">
                        <span className="suggested-card-fees-title">{"Annual Fee"}</span><br/>
                        <span className="suggested-card-fees-value"> {cardData.feesAndCharges.annualFees} </span>
                    </div>
                    <div className="suggested-card-apply-btn" onClick={applyNow}> {"Apply Now"} </div>
                </div>
            </div>
            { matchData?.isAnnualFeesWaived && cardData.feesAndCharges.annualFees != "0" && (<div className="suggested-card-annual-waived-off">
                <img 
                    style={{marginRight : "0.5rem"}} 
                    alt="party"
                    src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/party.svg"}/>
                <div> {"Congratulations! Annual Fee will be waived based on your spending"} </div>
            </div>)}
                <div className="suggested-card-total-rewards" 
                    onClick={ev => {ev.preventDefault(); showRedemptionTypes(!isShowRedemptionTypes)}}  
                    style={{ justifyContent : "space-between" }}> 
                <div className="reward-text">
                    <img alt="suggested-card-reward-img" className="suggested-card-reward-img" src={rewardType[matchData?.dataToSend?.type]?.["src"]}/>
                    <div className="suggested-card-reward-value"> 
                        {matchData?.dataToSend?.text.indexOf("worth") > -1 
                            ? ( <Fragment>
                                <b  style={{fontSize : "0.75rem"}}>{ isUserLoggedIn ?  matchData?.dataToSend?.text.split("worth")[0] : "*****"} </b>
                                <span  style={{fontSize : "0.75rem"}}> { "worth " + matchData?.dataToSend?.text.split("worth")[1]} </span>
                            </Fragment>
                            ) 
                            : (
                            <Fragment>
                                <b  style={{fontSize : "0.75rem"}}>{ isUserLoggedIn ?  matchData?.dataToSend?.text.split("Total")[0] : "****"} </b>
                                <span  style={{fontSize : "0.75rem"}}> {matchData?.dataToSend?.text.split("Total")[1]} </span>
                            </Fragment>
                            ) }
                    </div>
                </div>
                { rewardType[matchData?.dataToSend?.type]?.isAccordian && <img
                    style={{cursor :"pointer"}}  
                    src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-down-icon.svg"/> }
            </div>
            <div style={{position:"relative"}}>
                <div className={ !isUserLoggedIn ? `isblur` : `show`}>
                    { isShowRedemptionTypes && matchData.dataToSend.redemptionTypes &&  <div
                            style={{padding : "1rem"}} 
                            className={ isShowRedemptionTypes ? "redem-opt-active" : "redem-opt-in-active"}>
                            <div className="redemption-opts-title"> {"Redemption options*:"}  </div>
                            <div className="show-redemption-options-container">
                                {matchData.dataToSend.redemptionTypes.map(ele => 
                                    (
                                        <div className="suggested-card-redemption-opt">  
                                            <img 
                                                alt="suggested-card-reward-img"
                                                className="suggested-card-reward-img" 
                                                src={redemptionTypes[ele.type]}/>
                                            <div className="suggested-card-reward-text"> 
                                                <b style={{fontSize : "0.75rem"}}> { isUserLoggedIn ?  ele.text.split("worth")[0] : "*****"} </b>
                                                { "worth "  + ele.text.split("worth")[1] + " *"}
                                            </div>
                                        </div>
                                    )    
                                )}
                            </div>
                        </div> 
                    }
                    { matchData?.dataToSend?.vouchers && (
                        <div className="suggested-card-total-rewards"> 
                            <img 
                                alt="suggested-card-reward-img"
                                style={{cursor : "pointer"}} 
                                className="suggested-card-reward-img" 
                                src={rewardType["vouchers"]["src"]}/>
                            <span className="suggested-card-reward-value"> 
                                <b style={{fontSize : "0.75rem"}}>{ isUserLoggedIn ? matchData?.dataToSend?.["vouchers"]?.["text"].split("worth")[0] : "******"} </b>
                                <span style={{fontSize : "0.75rem"}}> { "worth " +  matchData?.dataToSend?.["vouchers"]?.["text"].split("worth")[1] !== undefined ? matchData?.dataToSend?.["vouchers"]?.["text"].split("worth")[1] : "" + "*"} </span>
                            </span>
                    </div>
                    )}
                </div>
                <div className={isUserLoggedIn  ? "hide-lock" : "show-lock"}> 
                    <img alt="lock" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/lock.svg"}/>  
                </div>
            </div>     
            <div style={{ display : isUserLoggedIn ? "block" : "none"}}>
                <div className="suggested-card-footer"> 
                    <div className="available-features-badges-container"> 
                        <span style={{margin : "0 0.25rem 0.25rem 0", fontSize :"0.75rem"}}>{"Avaliable features :"}</span> 
                        {Object.keys(availableComplimentaryFeatures).map( ele => 
                            (<div className="feature-badge" style={{ 
                                background : matchData?.features?.[ele] && "#E6F3E5",
                                color : matchData?.features?.[ele] &&  "#108510"  
                            }}> {availableComplimentaryFeatures[ele]} </div>)
                        )}
                        
                    </div>
                    <div style={{display:"flex" , alignItems:"center", margin : "0.5rem 0", fontSize : "0.75rem"}}>
                        {"Best for these"}
                        <div className="best-for-icons-container">  {<BestForIcons bestFor={cardData.bestFor} />} </div>
                    </div>
                    <div style={{   
                        display : "flex", alignItems : "center" , justifyContent : "space-between",
                        margin: "0 -1rem -1rem -1rem", padding: "0.5rem", background: "#eef7ff", 
                        fontSize : "0.875rem"}}>
                        <span> {"Card Details"} </span>
                        <button style={{background : "#eef7ff", border : "none"}} className="details-btn" onClick={ pageNavigate } > {"->"} </button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default SuggestedCard;