import React from 'react';
import "./styles/header.css";
import Rating from '../ratings';
import { Link, navigate } from 'gatsby';
function CardMetaHeader(props){
    const {headerDetails = {}} = props;
    const { displayName, annualFees,  key, rating , feesAndCharges , bestFor , applyLinkUrl, bankId  } = headerDetails;
    return (
    <div className='cards-metadata-header'>
        <div className='header-sec-1'>  
            <div className='card-title'>  {displayName} </div>
            <div className='card-rating-containter'> 
                <span style={{fontWeight:"400"}}> {"Our Rating"} </span> 
                 <Rating rating={rating} customStyle={{marginLeft:"0.5rem"}}/>
            </div> 
        </div>
        <div className='header-sec-2'> 
            <div className='header-annual-fees'>
                <h3> Annual Fees </h3> 
                {feesAndCharges.annualFees !== "Not Available" && feesAndCharges.annualFees != "0" ? <Badge 
                icon={"https://cdn-icons-png.flaticon.com/512/3998/3998647.png"} 
                text={feesAndCharges.annualFees}
                background={"linear-gradient(#FFE793,#FFD9B6)"}/>  : <Badge text={"Free"}/>} 
            </div>
            <div onClick={ev =>{
                ev.preventDefault();
                ev.stopPropagation();
                window.dispatchEvent(new CustomEvent("applyForCard", { detail :  {cardId : key , bankId} }));
            }}>
                {/* <a href={applyLinkUrl} target="_blank"> */}
                    <button className='apply-now-link'>
                        {"Apply Now"}     
                    </button>
                {/* </a> */}
                {/* <Link to={applyLinkUrl} >
               
                </Link> */}
            </div>
        </div>
    </div>
    )
}
function Badge(props){
    const {text , icon , background} = props;
    return (<div 
        className='badge' style={{background}}> 
        {icon && <img  
            alt={text}
            src={icon} 
            className="icon-image"/>} {text} 
        </div>)
}

export default CardMetaHeader;

