import React, { Fragment, useEffect, useState } from "react";
import CardWithAnalytics from './cardWithAnalytics';
import SpendsSidebar from "./spendsSidebar";
import Chart from "./chart";
import CompareCardData from '../compareCardData';
import SuggestedCard from "./suggestedCard";
import SuggestedCardDesktop from "./suggestedCardDesktop";
import FooterLogin from "../loginform/footerLogin";

import "./styles/styles.css";
import LoginForm from "../loginform";

function MyCards(props){
    const {matchData = [] , userSpends = {}} = props;
    const [ compareAllCardsView , setCompareCardsView ] = useState(false);
    const [ showUserSpends, setShowUserSpends ] = useState(true);
    const [userDetails,setUserDetails] = useState({});
    const [showFooterLogin,setShowFooterLogin] = useState(true);
    
    function updateUserDetails(ev){
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo") || "{}");
        if(Object.keys(userInfo).length == 0){
            window.dispatchEvent(new Event("UserLogin"));
        }
        setUserDetails(userInfo);
        if(userInfo["userIdentifier"]){
            setShowFooterLogin(false);
        }
    }

    useEffect( () => {
        window.addEventListener('session-storage', updateUserDetails);
        updateUserDetails();
        if(Object.keys(userDetails).length <= 1){
            window.dispatchEvent(new Event("UserLogin"))
        }
        const timeout = setTimeout( () => { 
            if(Object.keys(userDetails).length > 1){
                window.dispatchEvent(new Event("talkToExpertModal"));
            }}  , 7000);

        return () => {
            clearTimeout(timeout);
            window.removeEventListener("session-storage",updateUserDetails);
        }
    } , [] );

    let maxSavings = Math.ceil(matchData.reduce( (acc, ele) => { 
        acc =  Math.max(acc, ele.matchData.maxRedemptionValue)
        return acc; 
    }, 0)/1000) * 1000;

    return(
        <div>
            <div style={{padding : "1rem"}}> 
                <div className="my-cards-show-mobile-view">
                    <span className="my-cards-page-title">
                        {`Congrats! You can save up to ₹${maxSavings} with below Cards!`}
                    </span>  <br />
                    {/* <span className="my-cards-page-title">{"Apply now!🚀"}</span> */}
                </div>
                <div className="show-desktop-view" style={{textAlign : "center"}}> 
                    <span className="my-cards-page-title">{
                        `Congrats! You can save up to ₹${maxSavings} with below Cards!` 
                    }</span>  <br />
                </div>
            </div>
            <div 
                onClick={ev => {
                    updateUserDetails();
                }}
                style={{position : "relative", cursor : `${Object.keys(userDetails).length > 1 ? "auto" : "pointer"}`}}>
                <div className={`my-cards-show-cards ${Object.keys(userDetails).length <= 1 ?  " showBlurDesktop" : "" }`}>
                    <SpendsSidebar userSpends={userSpends} showUserSpends={showUserSpends} setShowUserSpends={setShowUserSpends} />
                    <div className={`cards-for-me-container`} style={{ flexBasis : showUserSpends ? "70%" : "65%" }}>
                        {/* <div className="my-cards-compare-cards-btn-container">
                            <button 
                                className="my-cards-compare-btn" 
                                style={{padding:"1rem"}}
                                onClick={ ev => {setCompareCardsView(!compareAllCardsView)} }> 
                                <div className="card-data-category-icon-div" style={{ marginRight: "0.5rem"}} > 
                                    <img src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/compare.svg"} /> 
                                </div>
                                {"Compare Cards"} 
                                </button>
                        </div> */}
                        {matchData.map( ele => (
                            <Fragment>
                                <div className="show-mobile-view">
                                    <SuggestedCard 
                                        isUserLoggedIn={Object.keys(userDetails).length > 1}
                                        key={ele.cardData[0].key} 
                                        cardData={ele.cardData[0]} 
                                        matchData={ele.matchData}
                                    />  
                                </div>
                                <div className="show-desktop-view">
                                    <SuggestedCardDesktop  
                                        isUserLoggedIn={Object.keys(userDetails).length > 1}
                                        key={ele.cardData[0].key} 
                                        cardData={ele.cardData[0]} 
                                        matchData={ele.matchData}
                                    />
                                </div>
                                {/* <div className="show-desktop-view">
                                    <CardWithAnalytics 
                                        key={ele.cardData[0].key} 
                                        cardData={ele.cardData[0]} 
                                        matchData={ele.matchData} 
                                    />
                                </div>  */}
                            </Fragment>
                            ))
                        }
                    </div>  
                    <div className="show-mobile-view"> 
                        { showFooterLogin && <FooterLogin  close={ ev => {setShowFooterLogin(false); updateUserDetails()} }/>  }    
                    </div>
                </div>
                { Object.keys(userDetails).length <= 1 ?  <div
                    onClick={ev => window.dispatchEvent(new Event("UserLogin"))} 
                    className="show-lock-desktop"> 
                    <img alt="lock" src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/lock.svg"}/>  
                 </div> : "" }  
            </div>
        </div>)
    }

export default MyCards;