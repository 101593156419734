import React from "react";
import "./styles.css";

function Rating(props){
    const {rating , customStyle} = props;
    let [ score, outOf ] = rating.split("/");
    let ratingArr = [];
    score = score/2;
    let isHalf = false;
    if(score%1 !=0){
        isHalf = true;
        score = score - score%1;
        ratingArr.push(<span className='fa fa-star-half-o checked'> </span>);
    }
    for(let i=0;i<score;i++){
        ratingArr.unshift(<span class="fa fa-star checked"></span>);
    }
    if( 5-score >=1 ){
        if(isHalf){
            for(let i=0;i<5-score-1;i++){
                ratingArr.push(<span class="fa fa-star"></span>)
            }
        }else{
            for(let i=0;i<5-score;i++){
                ratingArr.push(<span class="fa fa-star"></span>)
            }  
        }
        
    }
    return (
        <div style={customStyle}>
            {ratingArr || ""}
        </div>
    )
}

export default Rating;