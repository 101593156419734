export const displayBoxes = [
    {
        key : "rewardType",
        title : "Reward Type",
        descriptionKeys : ["cardType"],
        dataSource : "card"
    },
    {
        key : "joining-fees",
        title : "Joining Fees",
        descriptionKeys : ["feesAndCharges.joiningFees"],
        dataSource : "card"
    },
    {
        key : "max-redemption-option",
        title : "Pefered Redemption",
        descriptionKeys : ["maxRedemptionCategory"],
        dataSource : "match"
    },
    {
        key : "feature",
        title : "Features",
        hasMultiLinedDescription: true,
        descriptionKeys : ["features.domesticLounge", "features.internationalLounge", "features.golf"],
        dataSource : "match"
    },
    // {
    //     key : "golf-access",
    //     title : "Golf",
    //     descriptionKeys : [],
    //     dataSource : "match"
    // }
]

export const rewardType = {
    cashback : {
        src : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/cashback-icon.svg",
        isAccordian : false,
    },
    rewards : {
        src : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/reward-gift.svg",
        isAccordian : true,
    },
    vouchers : {
        src : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/voucher.svg",
        isAccordian : false,
    }
}

export const redemptionTypes = {
    airmiles : "https://credzyimages.s3.ap-south-1.amazonaws.com/airplane.svg",
    cashback : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/cashback-icon.svg",
    voucher : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/voucher.svg"
}

export const availableComplimentaryFeatures = {
    "internationalLounge" : "International Lounge",
    "domesticLounge" : "Domestic Lounge",
    "golf" : "Golf Rounds",
}