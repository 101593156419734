import React from "react";
import "./styles.css";

function MmcTooltip(props){
    const { text , customStyle = {} } = props;

    return (
        <div className="tooltip">
            {props.children}
            <div className="tooltip-text" style={customStyle.text}> {text} </div>
        </div>
    )
}

export default MmcTooltip;